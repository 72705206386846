import React from 'react'
import Layout from '../../components/Layout'
import { rhythm } from '../../utils/typography'
import { presets } from '../../utils/theme'

const styles = {
  section: {
    height: rhythm(27),
    width: 'auto',
  },
  container: {
    margin: 'auto',
    paddingTop: '10rem',
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: rhythm(1.5),
    [presets.Desktop]: {
      fontSize: rhythm(2),
    },
  },
  text: {
    textAlign: 'center',
    fontSize: rhythm(1),
    [presets.Desktop]: {
      fontSize: rhythm(1.5),
    },
  },
}

const thanks = () => (
  <Layout>
    <div css={styles.section}>
      <div css={styles.container}>
        <h1 css={styles.title}>Thank you!</h1>
        <p css={styles.text}>We will be reaching out to you shortly</p>
      </div>
    </div>
  </Layout>
)
export default thanks
